<template>
  <div>
    <div class="head-area">
      <div class="head-label">
        <div style="padding-top: 80px;">
          企业培训服务
        </div>
        <p style="font-size: 16px;padding-bottom: 50px;padding-top: 10px">实施有计划、有系统的培养和训练活动提高和改善员工的知识、技能、工作方法、工作态度以及工作的价值观，发挥最大潜力提高个人和组织的业绩</p>
      </div>
      <div class="head-btn">
        <div @click="addneed()" style="background-color: #0db88a;padding: 10px 30px;border-radius: 2px;cursor:pointer;">
          发布需求
        </div>
      </div>
    </div>
    <div style="width: 1440px;margin: 0 auto;background-color: white">
      <img :src="png_train1" alt="" style="">
      <div style="width: 100%;background-color: #F5F7FA;padding: 60px 0">
        <div class="title" style="margin-top: 0">精品课程</div>
        <div class="company-list">
          <div class="logo-list">
            <img :src='baseURL + item' v-for="item in course" style="width: 380px;height: 180px;margin-top: 24px;">
          </div>
        </div>
      </div>

<!--      <img :src="png_train2" alt="" style="">-->
      <div style="width: 1440px;padding-bottom: 40px">
        <div class="title">我们的客户</div>
        <div class="intro">我们已经为百余家企业提供了职业素养、技能管理、人力资源等方面的优质培训，遍布金融、地产、汽车等行业</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in customer" style="width: 228px;height: 80px;margin-bottom: 20px">
          </div>
        </div>
<!--        <img :src="png_labor3" alt="" style="">-->
      </div>
    </div>
  </div>
</template>

<script>
import png_head from '@/assets/images/company/companyHead.jpg'
import png_train1 from '@/assets/images/company/Train1.png'
import png_train2 from '@/assets/images/company/Train2.png'
import logo from '@/assets/images/company/Train_logo1.png'
import png_labor3 from '@/assets/images/company/labor3.png'
import { companyTrain_url , baseURL} from '../../utils/request/apis'

export default {
  name: "CompanyTrain",
  data(){
    return{
      png_head,
      png_train1,
      png_train2,
      logo,
      png_labor3,
      course:[],
      customer:[],
      baseURL :'',
    }
  },
  created() {
    this.baseURL = baseURL;
    this.getListData()
  },
  methods: {
    addneed(){
      const url = this.$router.resolve({ name: 'companytrain' })
      window.open(url.href)
    },
    getListData(){
      var courseTemp = '';
      var customerTemp = '';
      this.$http.get(companyTrain_url).then(res => {
        if(res.success){
          console.log("1111",res);
          const temp = res.result.records;
          for(var i = 0 ; i < temp.length ; i++){
            if(temp[i].type == 0){
              courseTemp += temp[i].image;
              courseTemp += ','
            }
            if(temp[i].type == 1){
              customerTemp += temp[i].image
              customerTemp += ','
            }
          }
          if(courseTemp.endsWith(',')){
            courseTemp = courseTemp.substring(0,courseTemp.length-1);
          }
          if(customerTemp.endsWith(',')){
            customerTemp = customerTemp.substring(0,customerTemp.length-1);
          }
          this.course = courseTemp.split(',');
          this.customer = customerTemp.split(',');
          console.log("course",this.course);
          console.log("customer",this.customer);
        } else {
          this.$message(res.message);
        }
      })
    },
  }
}
</script>

<style scoped>
.head-area{
  width: 100%;
  height: 300px;
  background: white;
  padding: 0px;
  background-image: url('../../assets/images/company/companyHead.jpg');
  background-size: 100% 100%;
}
.head-label {
  /*display:flex;*/
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /*line-height:240px;*/
  font-size:30px;
}
.head-btn {
  display:flex;
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /* background-color:#0db88a; */
  /* width: fit-content; */
  font-size:13px;
  margin-top: 0px;
}
.title{
  margin-top: 60px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: #303133;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.intro{
  margin-top: 8px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #606266;
}
.company-list{
  width: 1200px;
  margin: 0 auto;
  margin-top: 32px
}
.logo-list{
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
</style>
